

























import { defineComponent, provide } from "@vue/composition-api";
import { getMembership } from "@/api/membershipService";
import { userModule } from "@/store/modules/user";
import MembershipInfoCustomer from "@/components/membership/MembershipInfoCustomer.vue";
import InvoiceList from "@/components/membership/InvoiceList.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "Membership",
  components: {
    MembershipInfoCustomer,
    InvoiceList,
  },
  setup() {
    const { isLoading, data: membership, refresh } = useAsyncData(() =>
      getMembership(userModule.state.userId),
    );

    provide("getMembership", refresh);

    return {
      isLoading,
      membership,
    };
  },
});
