






















































import { computed, defineComponent } from "@vue/composition-api";
import { listReceipts } from "@/api/membershipService";
import { userModule } from "@/store/modules/user";
import InvoiceCard from "@/components/membership/InvoiceCard.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "InvoiceList",
  components: { InvoiceCard },
  setup() {
    const { data: invoices, isLoading } = useAsyncData(() => listReceipts(userModule.state.userId));

    const paid = computed(() => invoices.value?.filter((invoice) => invoice.invoice.paid));
    const unpaid = computed(() => invoices.value?.filter((invoice) => !invoice.invoice.paid));

    return {
      invoices,
      isLoading,
      paid,
      unpaid
    };
  },
});
