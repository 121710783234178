

















import { computed, defineComponent, PropType } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "MembershipInfoCustomer",
  props: {
    membership: {
      type: Object as PropType<Learnlink.Membership.default>,
      required: true,
    },
  },
  setup(props) {
    const endPrice = computed(() => {
      return (props.membership.subscription?.plan.amount || 0) / 100;
    });

    const productName = props.membership.product?.name;

    return {
      endPrice,
      productName,
    };
  },
});
